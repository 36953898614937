import React, { useState } from "react";

const positions = [
  "Backend Developer",
  "Backend Engineer",
  "Cloud Engineer",
  "Founding Engineer",
  "Frontend Developer",
  "Frontend Engineer",
  "Full Stack Developer",
  "Full Stack Engineer",
  "Fullstack Python Developer",
  "Javascript Developer",
  "Node.JS Engineer",
  "Node.JS Developer",
  "Python Developer",
  "Senior Software Engineer",
  "Software Engineer",
  "Systems Engineer",
];

const PositionInput = ({ position, setPosition }) => {
  const [customPosition, setCustomPosition] = useState("");

  const handleRadioChange = (e) => {
    setPosition(e.target.value);
    if (e.target.value !== "Others") {
      setCustomPosition("");
    }
  };

  const handleCustomChange = (e) => {
    setCustomPosition(e.target.value);
    setPosition(e.target.value);
  };

  return (
    <div>
      {positions.map((pos) => (
        <label key={pos}>
          <input
            type="radio"
            value={pos}
            checked={position === pos}
            onChange={handleRadioChange}
          />
          {pos}
        </label>
      ))}
      <label>
        <input
          type="radio"
          value="Others"
          checked={!positions.includes(position)}
          onChange={handleRadioChange}
        />
        Others
      </label>
      {!positions.includes(position) && (
        <input
          type="text"
          placeholder="Enter position"
          value={customPosition}
          onChange={handleCustomChange}
        />
      )}
    </div>
  );
};

export default PositionInput;
