export function generateCoverLetter(position, company) {
  return `Dear Hiring Manager
  
I am excited to be applying for the ${position} position at ${company}. As someone who is highly focused and attentive to detail, I thrive on building quality systems that surpass end user's expectations. I am thrilled at the opportunity to show off my technical expertise and leadership skills as part of ${company}'s expert team.
  

During my fast-paced full-time internship at OnlineSales.ai, I played a key role in developing a backend module that integrated image search functionalities with platforms like Unsplash, Shutterstock, and Pexels into the existing dashboard. As part of a dynamic team of two, I designed and implemented resilient REST APIs using Node.js and Express, allowing users to seamlessly select and post images with captions across various social media platforms. Leveraging Redis for caching and MySQL for database schema design and integration, we developed a microservice from scratch, ensuring high performance and scalability. Our solution significantly improved user engagement and satisfaction, catering to more than 22K active users and enhancing the overall user experience. Throughout this process, I utilized Postman for thorough API testing and employed Bash scripting to automate various tasks, ensuring robust and reliable integrations.
  

Working at True Sparrow Systems, I excelled in full-stack development and backend design, leveraging deep expertise in system architecture, scalability, and best coding practices in a fast-paced startup environment. I led multiple full-stack projects from scratch, including Whisper Chain, where I implemented a backend service using Test-Driven Development, designed a relational database schema, and created comprehensive documentation and REST APIs. The tech stack included AWS, Docker, React, Node.js, Express, Lens protocol, IPFS, MySQL, RabbitMQ, and Git, handling over 10K peak requests from 5K+ lensters. I also led a project from scratch, designing system architecture, database schema, and REST APIs. By using headless browsers, I scraped and curated funding data from 50K+ news articles for 5000+ organizations, resulting in TrueSparrow securing a client and increasing revenue by over $66K. The tech stack included AWS, Docker, Next.js, Node.js, Express, PostgreSQL, Memcached, Playwright, and GPT-3.5 Turbo. Additionally, I developed and shipped an iOS app from scratch using SwiftUI and Xcode. I am highly proficient in React.js and Next.js and have extensive experience using AI tools like CoPilot, boosting my productivity sevenfold.
  

I have contributed to over seven different open-source organizations across more than ten repositories, showcasing my dedication to community-driven development. My participation in hackathons organized by ETHIndia and TrueSparrow led to my winning two of them, further highlighting my competitive edge. Additionally, I have developed and published npm packages that assist many developers. Independently, I worked on a full-stack freelance project, the Turf Reservation system, in collaboration with local turf businesses, serving over 10K people and generating over 100K INR in revenue for the client. I have also deployed various personal full-stack projects detailed in my CV.
  

In my current contractual role with ScaleAI, I am engaged in Reinforcement Learning with Human Feedback (RLHF) and Supervised Fine-Tuning(SFT) tasks to enhance the capabilities of multi-turn, state-of-the-art large language models.
  

Thank you for your time and consideration. I am looking forward to learning more details about the ${position} position at ${company}. I am excited about the opportunity to leverage my unique range of skills, and I am eager to demonstrate my commitment to developing world-class software solutions for ${company}.
  
  

Sincerely,
Kartik Kapgate | 10kartikkapgate@gmail.com | +91 7774920748`;
}
