import React, { useState } from "react";
import { PDFDocument, rgb } from "pdf-lib";
import { generateCoverLetter } from "./cover-template";
import PositionInput from "./PositionInput";
import CompanyInput from "./CompanyInput";
import "./App.css";

function App() {
  const [position, setPosition] = useState("Software Engineer");
  const [company, setCompany] = useState("");

  const generatePDF = async () => {
    try {
      const pdfDoc = await PDFDocument.create();
      const page = pdfDoc.addPage();

      const headerText = `Kartik <> ${company}`; // Header text with company name
      const text = generateCoverLetter(position, company);

      const fontSize = 11;
      const lineHeight = fontSize * 1.2; // Decrease this multiplier to decrease line spacing
      const maxWidth = page.getWidth() - 40; // Assuming a margin of 20 units on each side
      const startX = 25;
      let startY = page.getHeight() - 80; // Start 20 units from the top

      // Use a standard font
      const font = await pdfDoc.embedStandardFont("Helvetica");
      const fontBold = await pdfDoc.embedStandardFont("Helvetica-Bold");

      page.drawText(headerText, {
        x: startX + 160,
        y: startY + 30,
        size: fontSize + 4,
        font: fontBold,
        color: rgb(25 / 255, 56 / 255, 3 / 255),
        maxWidth: maxWidth,
      });

      // write text to pdf file directly
      page.drawText(text, {
        x: startX,
        y: startY,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
        maxWidth: maxWidth,
        lineHeight: lineHeight,
      });

      const pdfBytes = await pdfDoc.save();

      // Create a Blob from the PDF bytes
      const blob = new Blob([pdfBytes], { type: "application/pdf" });

      // Generate a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create an anchor element and trigger a download
      const a = document.createElement("a");
      a.href = url;
      a.download = `Kartik for ${company}.pdf`; // Name of the downloaded file
      document.body.appendChild(a); // Append the anchor to the body
      a.click(); // Trigger a click on the anchor
      document.body.removeChild(a); // Clean up
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h2>Cover Letter Generator</h2>
        <PositionInput position={position} setPosition={setPosition} />
        <CompanyInput company={company} setCompany={setCompany} />
        <button onClick={generatePDF}>Generate PDF</button>
      </header>
    </div>
  );
}

export default App;
