import React from "react";

const CompanyInput = ({ company, setCompany }) => {
  return (
    <input
      type="text"
      placeholder="Company Name"
      value={company}
      onChange={(e) => setCompany(e.target.value)}
    />
  );
};

export default CompanyInput;
